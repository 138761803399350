<template lang="pug">
BaseDialog.delete-warning(
  v-if="isShow"
  isShow=true
  isDanger=true
  text="Are you sure you want to delete this scene?"
  @confirm="confirm"
  @closeOverlay="close"
)
</template>

<script>

export default {
  name: 'DeleteSceneWarning',
  data() {
    return {
      isShow: false,
      callbackFunction: null,
    };
  },
  methods: {
    confirm() {
      this.callbackFunction();
      this.isShow = false;
    },
    close() {
      this.isShow = false;
      this.callbackFunction = null;
    },
  },
  mounted() {
    this.$root.$on('show-delete-scene-warning', (callback) => {
      this.isShow = true;
      this.callbackFunction = callback;
    });
  },
};
</script>

<style lang="scss">
</style>
